import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { getFeedbackSuggestions, getTrendLine } from '../../../../api/customTrendsRequests';
import FeedbackItem from '../../../../shared/components/feedback/FeedbackItem';

/* ------------------------------------
   SKELETON LOADERS FOR THIS COMPONENT
   ----------------------------------- */

// Skeleton for getTrendLine
const SkeletonTrendLine = () => {
  return (
    <div
      style={{
        width: '500px',
        height: '300px',
        backgroundColor: '#eee',
        borderRadius: '4px',
        marginBottom: '20px',
      }}
    />
  );
};

// Skeleton for getFeedbackSuggestions
const SkeletonFeedbackSuggestions = () => {
  // Renders 5 placeholders
  return (
    <div style={{ marginTop: '20px' }}>
      {[1, 2, 3, 4, 5].map((n) => (
        <div
          key={n}
          style={{
            backgroundColor: '#eee',
            height: '20px',
            borderRadius: '4px',
            marginBottom: '10px',
          }}
        />
      ))}
    </div>
  );
};

/* ------------------------------------
   STYLED COMPONENTS
   ----------------------------------- */
const StyledFeedbackList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
  padding: 10px;
`;

const CustomTopicSummary = ({ custom_trend_id }) => {
  const [trendData, setTrendData] = useState([]);
  const [feedbackSuggestions, setFeedbackSuggestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  // Loading states for skeletons
  const [loadingTrendLine, setLoadingTrendLine] = useState(false);
  const [loadingFeedbackSuggestions, setLoadingFeedbackSuggestions] = useState(false);

  const itemsPerPage = 25;

  // ------------------------------------
  // Fetch trend line data
  // ------------------------------------
  const fetchTrendlineData = async () => {
    try {
      setLoadingTrendLine(true);
      const response = await getTrendLine(custom_trend_id);
      setTrendData(response.data.custom_trend_line || []);
    } catch (error) {
      console.error('Error fetching trend line data:', error);
    } finally {
      setLoadingTrendLine(false);
    }
  };

  // ------------------------------------
  // Fetch feedback suggestions
  // ------------------------------------
  const fetchFeedbackSuggestions = useCallback(
    async (page) => {
      try {
        setLoadingFeedbackSuggestions(true);
        const offset = page * itemsPerPage;
        const response = await getFeedbackSuggestions(custom_trend_id, itemsPerPage, offset);
        const { results, total_count } = response.data;
        setFeedbackSuggestions((prev) => [...prev, ...results]);
        // If fewer than itemsPerPage returned, no more to load
        if (results.length < itemsPerPage) {
          setCurrentPage(null);
        }
      } catch (error) {
        console.error('Error fetching feedback suggestions:', error);
      } finally {
        setLoadingFeedbackSuggestions(false);
      }
    },
    [custom_trend_id]
  );

  // ------------------------------------
  // Handle infinite scroll in suggestions list
  // ------------------------------------
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && currentPage !== null) {
      const nextPage = currentPage + 1;
      fetchFeedbackSuggestions(nextPage);
      setCurrentPage(nextPage);
    }
  };

  // ------------------------------------
  // On component mount / when ID changes
  // ------------------------------------
  useEffect(() => {
    if (custom_trend_id) {
      setFeedbackSuggestions([]);
      setCurrentPage(0);
      fetchTrendlineData();
      fetchFeedbackSuggestions(0);
    }
  }, [custom_trend_id, fetchFeedbackSuggestions]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <h3>Trend Line</h3>
          {loadingTrendLine ? (
            <SkeletonTrendLine />
          ) : (
            <LineChart
              width={500}
              height={300}
              data={trendData}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Line type="monotone" dataKey="feedback_count" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date_bucket" />
              <YAxis />
              <Tooltip />
            </LineChart>
          )}

          <StyledFeedbackList onScroll={handleScroll}>
            <h3>Feedback Suggestions</h3>
            {feedbackSuggestions.length === 0 && loadingFeedbackSuggestions ? (
              <SkeletonFeedbackSuggestions />
            ) : (
              feedbackSuggestions.map((feedback, index) => (
                <FeedbackItem key={index} feedback={feedback} />
              ))
            )}
            {/* Display a skeleton while loading additional feedback on scroll */}
            {loadingFeedbackSuggestions && feedbackSuggestions.length > 0 && (
              <SkeletonFeedbackSuggestions />
            )}
          </StyledFeedbackList>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomTopicSummary;