import React, { useState } from 'react';
import styled from 'styled-components';

const Ticket = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
`;

const TicketTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const TextContainer = styled.div`
  position: relative;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const FullText = styled.div`
  display: block;
  margin-top: 5px;
  max-height: ${(props) => (props.isExpanded ? 'none' : '50px')};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const TicketLink = styled.a`
  text-decoration: none;
  color: #007bff;

  &:hover {
    text-decoration: underline;
  }
`;

const SimilarityLevel = styled.div`
  font-style: italic;
`;

const FeedbackItem = ({ feedback }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleFullText = () => setIsExpanded(!isExpanded);

  const calculateSimilarityLevel = (score) => {
    if (score >= 0.5) return 'High';
    if (score >= 0.25) return 'Medium';
    return 'Low';
  };

  return (
    <Ticket>
      {feedback.title && (
        <TicketTitle>
          {feedback.title}
        </TicketTitle>
      )}

      <TextContainer>
        <FullText isExpanded={isExpanded}>
          {feedback.text || 'No additional text available.'}
        </FullText>
        {feedback.text && feedback.text.length > 50 && (
          <ToggleButton onClick={toggleFullText}>
            {isExpanded ? 'Show Less' : 'Show More'}
          </ToggleButton>
        )}
      </TextContainer>

      <BottomContainer>
        {feedback.link && (
          <TicketLink href={feedback.link} target="_blank">View Ticket</TicketLink>
        )}
        <SimilarityLevel>
          Similarity: {calculateSimilarityLevel(feedback.score)}
        </SimilarityLevel>
      </BottomContainer>
    </Ticket>
  );
};

export default FeedbackItem;