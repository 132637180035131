/* eslint camelcase: 0 */

import axios from 'axios';
import { base_url, tokenConfig } from './common';


export function create_custom_topic(title, description, review_id, source_id) {
  return axios.post(base_url + 'custom_trend/create_custom_trend', {
    'title': title,
    'description': description,
    'review_id': review_id,
    'source_id': source_id,
    'filters': {},
  }, tokenConfig({ 'Content-Type': 'application/json' }));
}

export function get_custom_topic(custom_topic_id) {
  return axios.get(base_url + 'custom_trend/get_custom_trend?custom_trend_id=' + custom_topic_id, tokenConfig());
}

export function get_custom_topics() {
  return axios.get(base_url + 'custom_trend/get_custom_topics', tokenConfig());
}


  export function get_next_feedback_to_annotate(
    custom_trend_id,
    feedback_text_ids = null,
    category = null
  ) {
    const payload = { custom_trend_id };
    // Only include feedback_to_update if we actually have something to update
    if (feedback_text_ids && category) {
      payload.feedback_to_update = {
        feedback_text_ids,
        category
      };
    }
    return axios.post(
      base_url + 'custom_trend/get_next_feedback_to_annotate',
      payload,
      tokenConfig({ 'Content-Type': 'application/json' })
    );
  }

export function getTrendLine(custom_trend_id) {
    return axios.get(base_url + 'custom_trend/get_trend_line?custom_trend_id=' + custom_trend_id, tokenConfig());
}


export function getFeedbackSuggestions(custom_trend_id, limit, offset) {
    return axios.post(base_url + 'feedback/get_feedback', {
        filters: {
            custom_trend_id,
            limit,
            offset
        }
    }, tokenConfig({ 'Content-Type': 'application/json' }));
}