/* eslint camelcase: 0 */

import axios from 'axios';
import {base_url,tokenConfig } from './common';



export function getFeedback(filters) {
  return axios.post(`${base_url}feedback/get_feedback`, filters, tokenConfig());
}





