import React, { lazy } from 'react';
import {
  MdFolderOpen,
  MdOutlineUploadFile,
  MdReplay,
  MdIntegrationInstructions,
  MdLightbulb, MdRocketLaunch, MdChatBubble,
} from 'react-icons/md';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { PiFolderFill } from 'react-icons/pi';
import { CgInsights } from 'react-icons/cg';
import CustomTopicsPage from '../../../../../pages/private/feedback/CustomTopicsPage';
import { TbWand } from 'react-icons/tb';

// Lazy-loaded pages
const ReportingPage = lazy(() => import('../../../../../pages/private/reporting/ReportingPage'));
const UploadSurveyDataPage = lazy(() => import('../../../../../pages/private/reporting/UploadSurveyDataPage'));
const SessionPlaylistPage = lazy(() => import('../../../../../pages/private/screen_record/SessionPlaylistPage'));
const SessionRecordScriptPage = lazy(() => import('../../../../../pages/private/screen_record/SessionRecordScriptPage'));
const KanbanPage = lazy(() => import('../../../../../pages/private/roadmap/KanbanPage'));
const GetStartedPage = lazy(() => import('../../../../../pages/private/home/GetStartedPage'));
const FeedbackAnalysisPage = lazy(() => import('../../../../../pages/private/feedback/FeedbackAnalysisPage'));

const iconStyle = {};

interface Dictionary<T> {
  [Key: string]: T;
}

export interface Category {
  name: string;
  id?: string;
  path?: string;
  icon?: JSX.Element;
  title?: boolean;
  folder?: boolean;
  child?: boolean;
  sticky?: boolean;
  badge?: boolean;
  badgeNumber?: Number;
  children?: Category[];
  divider?: boolean;
  component?: Function;
  params?: Dictionary<string>;
  canHaveSubcategories?: boolean;
}

export const categories: Category[] = [
  {
    name: 'Getting Started',
    title: true,
  },
  {
    name: '',
    path: 'get_started',
    id: 'get_started',
    children: [
      {
        name: 'Getting Started',
        path: 'get_started',
        component: GetStartedPage,
        icon: <MdRocketLaunch style={iconStyle} />,
      },
    ],
  },
  { name: 'Feedback', title: true },
  {
    name: '',
    path: 'feedback',
    id: 'feedback',
    children: [
      {
        name: 'Ask AI',
        path: 'ask_ai',
        component: FeedbackAnalysisPage,
        icon: <TbWand style={iconStyle} />,
      },
            {
        name: 'Custom topics',
        path: 'custom_topics',
        component: CustomTopicsPage,
        icon: <MdLightbulb style={iconStyle} />,
      },
      {
        name: 'Analysis',
        path: 'analysis',
        component: FeedbackAnalysisPage,
        icon: <CgInsights style={iconStyle} />,
      },


    ],
  },
  { name: 'Survey', title: true },
  {
    name: '',
    path: 'survey',
    id: 'survey',
    children: [
      {
        name: 'Upload Survey Data',
        path: 'upload_survey_data',
        component: UploadSurveyDataPage,
        icon: <MdOutlineUploadFile style={iconStyle} />,
      },
      {
        name: 'Reports',
        id: 'reporting',
        path: 'reporting',
        icon: <HiOutlineDocumentReport style={iconStyle} />,
        children: [
          {
            name: 'AI Generated',
            id: 'ai-generated',
            path: 'AI',
            component: ReportingPage,
            icon: <PiFolderFill style={iconStyle} />,
          },
          // Other sub-categories...
        ],
      },
    ],
  },
  { name: 'Session Replay', title: true },
  {
    name: '',
    path: 'session_replay',
    id: 'session_replay',
    children: [
      {
        name: 'Setup Recording',
        path: 'setup',
        component: SessionRecordScriptPage,
        icon: <MdIntegrationInstructions style={iconStyle} />,
      },
      {
        name: 'Watch User Sessions',
        path: 'replay',
        component: SessionPlaylistPage,
        icon: <MdReplay style={iconStyle} />,
      },
    ],
  },
  { name: 'Roadmap Planning', title: true },
  {
    name: '',
    path: 'roadmap',
    id: 'roadmap',
    children: [
      {
        name: 'Roadmaps',
        id: 'roadmaps',
        path: 'roadmaps',
        component: KanbanPage,
        icon: <PiFolderFill style={iconStyle} />,
        canHaveSubcategories: true,
        children: [
          // Additional sub-categories can be added here
        ],
      },
    ],
  },
];