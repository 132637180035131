import React, { useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { NavigationContext } from '../../context/NavigationContext';
import { useAuth } from '../../context/AuthContext';
import NotFoundPage from './NotFoundPage';  // your existing 404
import { getPrivatePaths } from './helpers/getPrivatePaths';

const DynamicNotFound = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { categoryList } = useContext(NavigationContext);

  // Gather all private paths
  const privatePaths = categoryList ? getPrivatePaths(categoryList) : [];

  // Because you may have routes like /feedback/analysis
  // but the user typed /feedback/analysis/typo
  // it won’t exactly match.
  // If you want to treat partial overlaps as private,
  // you can check with .startsWith(...) or a more advanced check:
  const isLikelyPrivate = privatePaths.some((p) =>
    location.pathname.startsWith(p)
  );

  if (isLikelyPrivate && !isAuthenticated) {
    // This user tried a “private” path but is not logged in—
    // send them to login, but preserve “where” they came from
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }

  // Otherwise, just show the existing NotFoundPage
  // (If the user is authenticated and it’s a private route that truly
  //  does not exist, they’ll see your normal 404 message, or
  //  you can customize a “private 404” if you want.)
  return <NotFoundPage />;
};

export default DynamicNotFound;
