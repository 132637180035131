import axios from 'axios';
import { base_url, tokenConfig } from './common';


export function askOpenQuestionRequest(filters, new_message) {
  const body = {
    'new_message': new_message,
    'filters': filters,
  }
  return axios.post(`${base_url}ask_ai/ask_open_question`, body, tokenConfig());
}