import { LineChart, Line } from 'recharts';

export const fetchSummary = async (searchTerm) => {
  // Here, resolve with static or conditional mock data based on `searchTerm`
  return Promise.resolve('This is a mock summary for the term: ' + searchTerm);
};

export const fetchInsights = async (searchTerm) => {
  // Provide mock insights
  return Promise.resolve([
    {
      insight: 'Mock Insight 1',
      trend: (
        <LineChart width={300} height={100} data={[{count: 2}, {count: 5}, {count: 3}]}>
          <Line type="monotone" dataKey="count" stroke="#8884d8" />
        </LineChart>
      ),
    }
    // Add more mock insights if needed
  ]);
};

export const fetchFeedback = async (searchTerm) => {
  // Provide mock feedback
  return Promise.resolve({
    results: [
      {
        feedback_text_id: 1,
        title: 'Mock Feedback Title',
        text: 'This is a mock feedback text for the term: ' + searchTerm,
        link: '#',
        score: 0.7,
      }
    ],
    totalCount: 1
  });
};