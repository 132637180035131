import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import styled from 'styled-components';
import { FiCheckCircle } from 'react-icons/fi';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

import {
  create_custom_topic,
  get_custom_topic,
  get_custom_topics,
  get_next_feedback_to_annotate,
} from '../../../api/customTrendsRequests';
import CustomTopicSummary from './components/CustomTopicSummary';

/* ------------------------------------
   SKELETON LOADERS FOR THIS COMPONENT
   ----------------------------------- */

// Skeleton for get_custom_topics
const SkeletonCustomTopicsList = () => {
  return (
    <div>
      {[1, 2, 3, 4].map((n) => (
        <div
          key={n}
          style={{
            marginBottom: '10px',
            backgroundColor: '#eee',
            height: '20px',
            borderRadius: '4px',
          }}
        />
      ))}
    </div>
  );
};

// Skeleton for get_custom_topic
const SkeletonSingleTopic = () => {
  return (
    <div>
      <div
        style={{
          backgroundColor: '#eee',
          height: '20px',
          borderRadius: '4px',
          width: '50%',
          marginBottom: '10px',
        }}
      />
      <div
        style={{
          backgroundColor: '#eee',
          height: '60px',
          borderRadius: '4px',
          marginBottom: '10px',
        }}
      />
    </div>
  );
};

// Skeleton for get_next_feedback_to_annotate
const SkeletonFeedbackRating = () => {
  return (
    <div>
      <div
        style={{
          backgroundColor: '#eee',
          height: '20px',
          borderRadius: '4px',
          width: '80%',
          marginBottom: '10px',
        }}
      />
      <div
        style={{
          backgroundColor: '#eee',
          height: '20px',
          borderRadius: '4px',
          width: '60%',
          marginBottom: '10px',
        }}
      />
    </div>
  );
};

/*
   --------------------------------------------------
   STYLED COMPONENTS FOR PAGE LAYOUT AND ITEMS
   --------------------------------------------------
*/
const StyledSection = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
`;

const StyledSectionSummary = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
`;

const StyledSectionHistory = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  height: 100%;
`;

const FlexSection = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  min-height: 220px;
  margin-bottom: 20px;
`;

const CustomTopicItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #5a9;
  margin-bottom: 10px;
`;

const saveCustomTopic = async (newCustomTopic) => {
  // Stub for saving custom topic to server if needed
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(newCustomTopic);
    }, 300);
  });
};

const CustomTopicsPage = () => {
  // ------------------------------------
  // Local state for skeleton toggling
  // ------------------------------------
  const [loadingCustomTopics, setLoadingCustomTopics] = useState(false);
  const [loadingSingleTopic, setLoadingSingleTopic] = useState(false);
  const [loadingNextFeedback, setLoadingNextFeedback] = useState(false);

  // ------------------------------------
  // Existing states
  // ------------------------------------
  const [feedbackQueue, setFeedbackQueue] = useState([]);
  const [userRatings, setUserRatings] = useState([]);
  const [currentFeedbackIndex, setCurrentFeedbackIndex] = useState(0);
  const [customTopics, setCustomTopics] = useState([]);
  const [editing, setEditing] = useState(false);
  const [customTrend, setCustomTrend] = useState({ title: '', description: '' });
  const [currentFeedback, setCurrentFeedback] = useState(null);
  // Currently selected Custom Topic
  const [selectedCustomTopicId, setSelectedCustomTopicId] = useState(null);

  // -----------------------------------------------------------------------
  // On mount, read custom_topic_id from URL if it exists
  // -----------------------------------------------------------------------
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlCustomTopicId = urlParams.get('custom_topic_id');
    if (urlCustomTopicId) {
      setSelectedCustomTopicId(urlCustomTopicId);
      fetchCustomTrend(urlCustomTopicId);
    }
  }, []);

  // -----------------------------------------------------------------------
  // Fetch all custom topics for the left-hand list
  // -----------------------------------------------------------------------
  useEffect(() => {
    const fetchCustomTopicsList = async () => {
      try {
        setLoadingCustomTopics(true);
        const response = await get_custom_topics();
        setCustomTopics(response.data.custom_topics);
      } catch (error) {
        console.error('Error fetching custom topics:', error);
      } finally {
        setLoadingCustomTopics(false);
      }
    };
    fetchCustomTopicsList();
  }, []);

  // -----------------------------------------------------------------------
  // Check if user is creating a new custom topic from external link
  // -----------------------------------------------------------------------
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');
    const reviewId = urlParams.get('review_id');
    const sourceId = urlParams.get('source_id');

    const initializeCustomTopic = async () => {
      if (action === 'create_custom_topic' && reviewId && sourceId) {
        try {
          // Create a new custom topic
          const createResponse = await create_custom_topic(null, null, reviewId, sourceId);
          const newCustomTopicId = createResponse.data.custom_trend_id;
          // Update URL
          window.history.replaceState(null, '', `?custom_topic_id=${newCustomTopicId}`);
          setSelectedCustomTopicId(newCustomTopicId);
          fetchCustomTrend(newCustomTopicId);
        } catch (error) {
          console.error('Error creating custom topic:', error);
        }
      }
    };
    initializeCustomTopic();
  }, []);

  // -----------------------------------------------------------------------
  // Fetch a single custom trend by ID, set local state, load next feedback
  // -----------------------------------------------------------------------
  const fetchCustomTrend = async (customTopicId) => {
    try {
      setLoadingSingleTopic(true);
      const result = await get_custom_topic(customTopicId);
      setCustomTrend(result.data.custom_trend);
      setEditing(false);
      loadNextFeedback(customTopicId);
    } catch (error) {
      console.error('Error fetching custom trend:', error);
    } finally {
      setLoadingSingleTopic(false);
    }
  };

  // -----------------------------------------------------------------------
  // Load the next piece of feedback to annotate for this custom topic
  // -----------------------------------------------------------------------
const loadNextFeedback = async (customTopicId, feedback_text_ids, category) => {
    try {
        setLoadingNextFeedback(true);
        const response = await get_next_feedback_to_annotate(customTopicId, feedback_text_ids, category);
        const nextFeedback = response.data.feedback;
        setCurrentFeedback(nextFeedback);
    } catch (error) {
        console.error('Error fetching next feedback:', error);
    } finally {
        setLoadingNextFeedback(false);
    }
};

  // -----------------------------------------------------------------------
  // Handle rating logic (thumbs-up, thumbs-down, skip)
  // -----------------------------------------------------------------------
const handleRating = async (rating) => {
    if (!currentFeedback) return;

    let category;
    switch (rating) {
        case 'thumbs-up':
            category = 'positive';
            break;
        case 'thumbs-down':
            category = 'negative';
            break;
        default:
            category = 'suggested';
    }

    try {
        // Update feedback item category on server
        // Load next feedback item immediately
        await loadNextFeedback(selectedCustomTopicId, [currentFeedback.feedback_text_id], category);
    } catch (error) {
        console.error('Failed to rate feedback:', error);
    }
};

  // -----------------------------------------------------------------------
  // Basic "accuracy" calculation for demonstration
  // -----------------------------------------------------------------------
  const calculateAccuracy = () => {
    const positiveFeedback = userRatings.filter((r) => r.rating === 'thumbs-up').length;
    const accuracy = userRatings.length
      ? (positiveFeedback / userRatings.length) * 100
      : 0;
    return accuracy.toFixed(2);
  };

  // -----------------------------------------------------------------------
  // Create (if no ID) or save changes (if there's an existing trend)
  // -----------------------------------------------------------------------
  const saveChanges = async () => {
    try {
      if (!selectedCustomTopicId) {
        // Creating a new custom trend
        const createResponse = await create_custom_topic(
          null,
          null,
          null, // reviewId
          null, // sourceId
          customTrend.title,
          customTrend.description
        );
        const newId = createResponse.data.custom_trend_id;
        window.history.pushState({}, '', `?custom_topic_id=${newId}`);
        setSelectedCustomTopicId(newId);
        fetchCustomTrend(newId);
      } else {
        // (Optional) Save changes to existing trend
        // e.g. await update_custom_topic(customTrend);
        console.log('Saving custom trend edits:', customTrend);
      }
      setEditing(false);
    } catch (error) {
      console.error('Error saving/creating custom trend:', error);
    }
  };

  // -----------------------------------------------------------------------
  // When user selects a custom topic from the left-hand list
  // -----------------------------------------------------------------------
  const handleTopicSelection = (id) => {
    window.history.pushState({}, '', `?custom_topic_id=${id}`);
    setSelectedCustomTopicId(id);
    setFeedbackQueue([]);
    setCurrentFeedbackIndex(0);
    fetchCustomTrend(id);
  };

  return (
    <Container
      fluid
      className="py-4"
      style={{
        backgroundColor: '#f7f8fc',
        minHeight: '100vh',
      }}
    >
      <Container style={{ maxWidth: '1100px', margin: '0 auto' }}>
        <Row>
          {/* -----------------------------------------------------------
             Left column: List of recent custom topics
             ----------------------------------------------------------- */}
          <Col md={3}>
            <StyledSectionHistory>
              <h3>Recent custom topics</h3>
              {loadingCustomTopics ? (
                <SkeletonCustomTopicsList />
              ) : (
                customTopics.map((topic) => (
                  <CustomTopicItem
                    key={topic.id}
                    onClick={() => handleTopicSelection(topic.id)}
                  >
                    {topic.title}
                  </CustomTopicItem>
                ))
              )}
            </StyledSectionHistory>
          </Col>

          {/* -----------------------------------------------------------
             Right column: Details, annotation interface, summary
             ----------------------------------------------------------- */}
          <Col md={9}>
            <StyledSection>
              <h3>Custom Trend Details</h3>
              {/* If we haven't selected a topic yet... */}
              {!selectedCustomTopicId ? (
                <>
                  <p>You have not selected a custom trend. Create a new one below:</p>
                  <Input
                    type="text"
                    placeholder="New Custom Trend Title"
                    value={customTrend.title}
                    onChange={(e) =>
                      setCustomTrend({ ...customTrend, title: e.target.value })
                    }
                  />
                  <Input
                    style={{ marginTop: '10px' }}
                    type="textarea"
                    placeholder="New Custom Trend Description"
                    value={customTrend.description}
                    onChange={(e) =>
                      setCustomTrend({ ...customTrend, description: e.target.value })
                    }
                  />
                  <Button
                    color="primary"
                    onClick={saveChanges}
                    style={{ marginTop: '10px' }}
                  >
                    Create
                  </Button>
                </>
              ) : loadingSingleTopic ? (
                // Skeleton for single topic loading
                <SkeletonSingleTopic />
              ) : (
                // If a topic is selected, display or edit existing details
                <>
                  {editing ? (
                    <>
                      <Input
                        type="text"
                        value={customTrend.title}
                        onChange={(e) =>
                          setCustomTrend({ ...customTrend, title: e.target.value })
                        }
                      />
                      <Input
                        type="textarea"
                        value={customTrend.description}
                        onChange={(e) =>
                          setCustomTrend({
                            ...customTrend,
                            description: e.target.value,
                          })
                        }
                      />
                      <Button color="primary" onClick={saveChanges}>
                        Save
                      </Button>
                      <Button color="secondary" onClick={() => setEditing(false)}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <p>
                        <strong>Title:</strong> {customTrend.title}
                      </p>
                      <p>
                        <strong>Description:</strong> {customTrend.description}
                      </p>
                      <Button color="primary" onClick={() => setEditing(true)}>
                        Edit
                      </Button>
                    </>
                  )}
                </>
              )}
            </StyledSection>

            {/* If no topic selected, skip feedback rating and summary */}
            {selectedCustomTopicId && (
              <>
                <Row>
                  <Col md={8}>
                    <FlexSection>
                      <h3>Chosen feedback to rate</h3>
                      {loadingNextFeedback ? (
                        <SkeletonFeedbackRating />
                      ) : currentFeedback && (
                        <div>
                          <p>Feedback: {currentFeedback.text}</p>
                          <Button color="success" onClick={() => handleRating('thumbs-up')}>
                            <FaThumbsUp />
                          </Button>
                          <Button color="danger" onClick={() => handleRating('thumbs-down')}>
                            <FaThumbsDown />
                          </Button>
                          <Button color="light" onClick={() => handleRating('skip')}>
                            Skip
                          </Button>
                        </div>
                      )
                      }
                    </FlexSection>
                  </Col>
                  <Col md={4}>
                    <FlexSection>
                      <IconWrapper>
                        <FiCheckCircle />
                      </IconWrapper>
                      <h2>Accuracy</h2>
                      <p>{calculateAccuracy()}%</p>
                    </FlexSection>
                  </Col>
                </Row>

                <StyledSectionSummary>
                  <CustomTopicSummary custom_trend_id={selectedCustomTopicId} />
                </StyledSectionSummary>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default CustomTopicsPage;