import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TbWand } from 'react-icons/tb';
import { getFeedback } from '../../../api/feedbackRequests';

const AppContainer = styled.div`
  padding: 10px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 10px;
  width: 100%;
`;

const SearchWrapper = styled.div`
  display: flex;
  position: relative;
  width: 70%;
  max-width: 600px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const AskAIButton = styled.button`
  padding: 10px;
  margin-left: 10px;
  border-radius: 4px;
  border: none;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #2980b9;
  }
`;

const ResultsContainer = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin-bottom: 10px;
`;

const TicketsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 20px;
`;

const Ticket = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
`;

const TicketTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ExpandButton = styled.button`
  margin-bottom: 5px;
  padding: 0;
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const FullText = styled.div`
  font-size: 0.9em;
  margin-bottom: 5px;
  white-space: pre-wrap;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`;

const TicketLink = styled.a`
  color: #0366d6;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SimilarityLevel = styled.div`
  font-size: 0.9em;
  color: #555;
`;

const InfoText = styled.div`
  font-size: 0.8em;
  color: #666;
  padding: 5px 0;
  text-align: left;
`;

const predefinedQuestions = [
  'List bugs that user mentions',
  'What do customers express when they are most frustrated?',
  'What are the worst features?',
  'What do people struggle with the most?',
  'What do users say about ____ feature?',
];

const FeedbackAnalysisPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [feedback, setFeedback] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const inputRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  const sourceId = urlParams.get('source_id');
  const reviewId = urlParams.get('review_id');

  useEffect(() => {
    fetchFeedback();
  }, [sourceId, reviewId, searchTerm]);

  const fetchFeedback = () => {
    const filters = {};
    if (sourceId) {
      filters.view_id = parseInt(sourceId, 10);
    }
    if (reviewId) {
      filters.similarity = { review_id: parseInt(reviewId, 10) };
    }
    if (searchTerm) {
      filters.searchTerm = searchTerm;
    }

    getFeedback(filters)
      .then((response) => {
        setFeedback(response.data.results);
        setTotalCount(response.data.total_count);
      })
      .catch((error) => {
        console.error('Error fetching similar feedback:', error);
      });
  };

  const handleSearch = () => {
    fetchFeedback();
  };

  const handleQuestionSelect = (question) => {
    setSearchTerm(question);
    setDropdownVisible(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const toggleTextDisplay = (index) => {
    setExpandedIndexes((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const truncateText = (text, wordsCount) => {
    let words = text.split(' ');
    return words.length > wordsCount ? words.slice(0, wordsCount).join(' ') + '...' : text;
  };

  return (
    <AppContainer id="clientcues_app">
      <SearchContainer>
        <SearchWrapper>
          <SearchInput
            ref={inputRef}
            type="text"
            placeholder="Enter questions about your feedback"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setDropdownVisible(true)}
            onBlur={() => setDropdownVisible(false)}
            onKeyDown={(e) => { if (e.key === 'Enter') handleSearch(); }}
          />
          {dropdownVisible && (
            <Dropdown>
              {predefinedQuestions.map((question, index) => (
                <DropdownItem key={index} onMouseDown={() => handleQuestionSelect(question)}>
                  {question}
                </DropdownItem>
              ))}
            </Dropdown>
          )}
          <AskAIButton onClick={handleSearch}>
            <TbWand style={{ marginRight: '5px' }} />
            Ask AI
          </AskAIButton>
        </SearchWrapper>
      </SearchContainer>
      <ResultsContainer>
        <InfoText>{totalCount} Results Found</InfoText>
      </ResultsContainer>
      <TicketsContainer>
        {feedback.length === 0 ? (
          <p>No similar feedback found.</p>
        ) : (
          feedback.map((item, index) => {
            const words = item.text ? item.text.split(' ') : [];
            const isExpanded = expandedIndexes.includes(index);
            return (
              <Ticket key={item.feedback_text_id}>
                {item.title && <TicketTitle>{item.title}</TicketTitle>}
                <FullText>
                  {isExpanded ? item.text : truncateText(item.text, 30)}
                </FullText>
                {words.length > 30 && (
                  <ExpandButton onClick={() => toggleTextDisplay(index)}>
                    {isExpanded ? 'See Less' : 'See More'}
                  </ExpandButton>
                )}
                <BottomContainer>
                  <TicketLink href={item.link || '#'} target="_blank">View Original</TicketLink>
                  <SimilarityLevel>
                    Similarity: {calculateSimilarityLevel(item.score)}
                  </SimilarityLevel>
                </BottomContainer>
              </Ticket>
            );
          })
        )}
      </TicketsContainer>
    </AppContainer>
  );
};

const calculateSimilarityLevel = (score) => {
  if (score >= 0.5) {
    return 'High';
  } else if (score >= 0.25) {
    return 'Medium';
  } else {
    return 'Low';
  }
};

export default FeedbackAnalysisPage;