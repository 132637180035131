import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BsLightbulb } from 'react-icons/bs'; // Import the lightbulb icon from react-icons

// Styled components for the layout of the 404 page
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
`;

const Icon = styled(BsLightbulb)`
  font-size: 100px;
  color: #007bff;
  margin-bottom: 16px;
`;

const Description = styled.p`
  font-size: 24px;
  margin-bottom: 32px;
`;

const HomeLink = styled(Link)`
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 18px;
  &:hover {
    background-color: #0056b3;
  }
`;

const NotFoundPage = () => {
  return (
    <Container>
      <Icon />
      <Description>Oops! The page you're looking for doesn't exist. Let's get you back on track!</Description>
      <HomeLink to="/">Go Back to Home</HomeLink>
    </Container>
  );
};

export default NotFoundPage;