export function getPrivatePaths(categoryList) {
  const paths = [];

  function recurse(categories, basePath = '') {
    categories.forEach((cat) => {
      const fullPath = cat.path
        ? `${basePath}/${cat.path}`.replace(/\/+/g, '/')
        : '';

      // If this category itself has a component => it is a “real” route
      if (cat.component) {
        paths.push(fullPath);
      }
      // If the category has children => keep going
      if (cat.children && cat.children.length) {
        recurse(cat.children, fullPath);
      }
    });
  }

  recurse(categoryList);
  return paths;
}